import React from 'react';
import { BsLinkedin,BsGithub} from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href='https://www.linkedin.com/in/mohamed-lemin-el-bechir-364b26173/'><BsLinkedin/></a>
    </div>
    <div>
    <a href='https://web.facebook.com/ObeyBoySb/'>  <FaFacebookF /> </a>
     
    
    </div>
    <div>
     <a href='https://github.com/Mohamedlemin'> <BsGithub /> </a>
    </div>
  </div>
);

export default SocialMedia;
