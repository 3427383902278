import React from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import { MdFileDownload } from 'react-icons/md'; 


import './Header.scss';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => (
  <div className="app__header app__flex">
    <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      <div className="app__header-badge">

      <div className="hello-cmp app__flex">
          <p className="p-text">Hello 👋 ,  I'm Mohamed lemine</p>
        </div>

        <div className="badge-cmp app__flex">
          {/* <span></span> */}
          <div style={{ marginLeft: 10 }}>
            <p className='p-text'>
            Experienced Java Developer proficient in Java, Spring Boot, Microservices, RESTful, AWS, PostgreSQL, and Git. Proven track record of overcoming challenges and delivering impactful results. Let's create something amazing together.
            </p>
            {/* <img src={images.logo} alt="logo" /> */}
          </div>
        </div>

        <div className="tag-cmp app__flex">
          <p className="p-text">I am currently located 📍 in the USA</p>
          <br/>
        </div>

       
        {/* <div className="tag-cmp1 app__flex">
        <a href="/MhmdLemin.pdf" download>
        <button className="resume-button">
              <MdFileDownload style={{ marginRight: 8 }} /> Download Resume
            </button>
        </a>
        </div> */}


       
      </div>
    </motion.div>

    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 1.5, delayChildren: 0.5 }}
      className="app__header-img"
    >
      {/* <img src={images.medlemin} alt="profile_bg" /> */}
      <motion.img
        whileInView={{ scale: [0, 1] }}
        transition={{ duration: 1.5, ease: 'easeInOut' }}
        src={images.medlemin}
        alt="profile_circle"
        className="overlay_circle"
      />
    </motion.div>

    <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles"
    >
      {[images.flutter, images.Spring,  images.angular].map((circle, index) => (
        <div className="circle-cmp app__flex" key={`circle-${index}`}>
          <img src={circle} alt="profile_bg" />
        </div>
      ))}
    </motion.div>
  </div>
);

export default AppWrap(Header, 'home');
