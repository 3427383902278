import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BlockContent from '@sanity/block-content-to-react';
import { client, urlFor } from '../../client';
import './BlogContent.scss';

const BlogContent = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const query = `*[_type == "blog" && slug.current == $slug][0]{
      title,
      "author": author->name,
      publishedAt,
      mainImage,
      excerpt,
      body,
      "categories": categories[]->title,
      tags
    }`;

    client.fetch(query, { slug }).then((data) => {
      setBlog(data);
    });
  }, [slug]);

  if (!blog) return <div></div>;

  return (
    <div className="blog-content">
      <header className="blog-header">
        <h1 className="blog-title">{blog.title}</h1>
        <div className="blog-info">
          {blog.author && <p className="blog-author">By {blog.author}</p>}
          {blog.publishedAt && <p className="blog-date">Published on {new Date(blog.publishedAt).toLocaleDateString()}</p>}
        </div>
        {blog.mainImage && <img className="blog-image" src={urlFor(blog.mainImage)} alt={blog.title} />}
      </header>
      {blog.excerpt && <p className="blog-excerpt">{blog.excerpt}</p>}
      <main className="blog-body">
        <BlockContent blocks={blog.body} imageOptions={{ w: 320, h: 240, fit: 'max' }} projectId="your_project_id" dataset="your_dataset_name" />
      </main>
      <footer className="blog-metadata">
        {blog.categories && <div className="blog-categories">Categories: {blog.categories.join(', ')}</div>}
        {blog.tags && <div className="blog-tags">Tags: {blog.tags.join(', ')}</div>}
      </footer>
    </div>
  );
};

export default BlogContent;
