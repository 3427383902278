import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { About, Footer, Header, Skills, Work } from "./container";
import { Navbar } from "./components";
import "./App.scss";
import Blogs from "./container/Blogs/Blogs";
import BlogContent from "./container/BlogContent/BlogContent";

const App = () => (
  <Router>
    <div className='app'>
      <Routes>
        <Route
          path='/'
          element={
            <>
              <Navbar />
              <Header />
              <About />
              <Work />
              <Skills />
              <Blogs />
              <Footer />
            </>
          }
        />
        <Route path='/blog/:slug' element={<BlogContent />} />
      </Routes>
    </div>
  </Router>
);

export default App;
