import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Blogs.scss';

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [filterBlog, setFilterBlog] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const navigate = useNavigate();

  useEffect(() => {
    const query = '*[_type == "blog"]';

    client.fetch(query).then((data) => {
      setBlogs(data);
      setFilterBlog(data);
    });
  }, []);

  const handleBlogFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'All') {
        setFilterBlog(blogs);
      } else {
        setFilterBlog(blogs.filter((blog) => blog.tags.includes(item)));
      }
    }, 500);
  };

  const handleBlogClick = (slug) => {
    navigate(`/blog/${slug}`);
  };

  return (
    <>
      <h5 className="head-text">EXPLORE <span>MY ARTICLES</span> </h5>

      <div className="app__blog-filter">
        {['All', 'Research Papers','Java', 'Microservice', 'React'].map((item, index) => (
          <div
            key={index}
            onClick={() => handleBlogFilter(item)}
            className={`app__blog-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__blog-portfolio"
      >
        {filterBlog.map((blog, index) => (
          <div className="app__blog-item app__flex" key={index} onClick={() => handleBlogClick(blog.slug.current)}>
            <div className="app__blog-img app__flex">
              <img src={urlFor(blog.mainImage)} alt={blog.title} />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                className="app__blog-hover app__flex"
              >
                <motion.div
                  whileInView={{ scale: [0, 1] }}
                  whileHover={{ scale: [1, 0.9] }}
                  transition={{ duration: 0.25 }}
                  className="app__flex"
                >
                  <AiFillEye />
                </motion.div>
              </motion.div>
            </div>

            <div className="app__blog-content app__flex">
              <h4 className="bold-text">{blog.title}</h4>
              {/* <p className="p-text" style={{ marginTop: 10 }}>{blog.categories.join(', ')}</p> */}
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Blogs, 'app__blogs'),
  'blogs',
  'app__primarybg',
);
