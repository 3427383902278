import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { process } from '../node_modules/framer-motion/dist/cjs/index';

export const client = sanityClient({
  projectId:'0g5sfrjm',
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token: 'skELAuGSNa0Nysc9d9sW7ECqVj26P0vaFSEECVOY7SwGoyakD5lVa22MZc5Nt11cd1IsApvTkXfoLu5Ai0aKHiYBIDtXifoRF1B0DYyu2UEWVSjSF545U2slpcJz1uns04FfDzCHgSt0wfGvqvnHEFzSobOR46JOGy4wJLsePEq2NVWdfW4s',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
